import React, { PureComponent } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Legend } from 'recharts';

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor='middle'>
        {value}
      </text>
    );
  }
}

export function CalculatorComparisonBlock({comparison, calculatorComparison, type}) {
    const [loanAmount, setLoanAmount] = React.useState(0);
    const [chartData, setChartData] = React.useState({});
    const [currentColor, setCurrentColor] = React.useState('#777');
    const [suggestedColor, setSuggestedColor] = React.useState('#000');

    React.useEffect(() => {
        if (Number(calculatorComparison.suggestion.payoff_breakdown[0]?.la) > Number(calculatorComparison.current.payoff_breakdown[0]?.la)) {
            setLoanAmount(Number(calculatorComparison.suggestion.payoff_breakdown[0]?.la));
        } else {
            setLoanAmount(Number(calculatorComparison.current.payoff_breakdown[0]?.la));
        }
        let clone = structuredClone(calculatorComparison.current.payoff_breakdown);
        if (type === 'occupation') {
            if (Number(calculatorComparison.suggestion.payoff_breakdown[0]?.pi) > Number(calculatorComparison.current.payoff_breakdown[0]?.pi)) {
                setCurrentColor('#cc0000');
                setSuggestedColor('#336600');
            } else if (Number(calculatorComparison.suggestion.payoff_breakdown[0]?.pi) < Number(calculatorComparison.current.payoff_breakdown[0]?.pi)) {
                setCurrentColor('#336600');
                setSuggestedColor('#cc0000');
            }
            for (let i = 0; i < clone.length; i++) {
                clone[i].spi = calculatorComparison.suggestion.payoff_breakdown[i].pi;
            }
        } else {
            if (Number(calculatorComparison.suggestion.payoff_breakdown[0]?.la) > Number(calculatorComparison.current.payoff_breakdown[0]?.la)) {
                setCurrentColor('#336600');
                setSuggestedColor('#cc0000');
            } else if (Number(calculatorComparison.suggestion.payoff_breakdown[0]?.la) < Number(calculatorComparison.current.payoff_breakdown[0]?.la)) {
                setCurrentColor('#cc0000');
                setSuggestedColor('#336600');
            }
            for (let i = 0; i < clone.length; i++) {
                clone[i].sla = calculatorComparison.suggestion.payoff_breakdown[i].la;
            }
        }
        setChartData(clone);
    }, []);

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ mt: 1, mb: 1 }}>
                <Grid item md={12} lg={6}>
                    <Typography component='div' variant='h6' fontWeight='bold' color='primary' sx={{textAlign:'center'}}>
                        {comparison.suggestion} (Suggestion)
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    <Grid container alignItems="center" justifyContent="center" spacing={2}>
                        <Grid item md={12} lg={4} sx={{textAlign:'center'}}>
                            <Paper elevation={0} sx={{backgroundColor:'#f7f7f7', p: 2, border: '1px solid #e2e2e2'}}>
                                <Typography component='div' fontWeight='bold' color='secondary'>
                                    YOUR INVESTMENT RATING
                                </Typography>
                                <Typography color='primary' variant='h1' component='div'  sx={{ mt: 2, mb: 2 }} fontSize='120px'>
                                    {calculatorComparison.suggestion.rating}
                                </Typography>
                                <Tooltip enterTouchDelay={0} title='This rating is calculated based on the average amount of money you are going to make in your industry versus the loan amount to payoff. Click for more information on how our calculations work.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                                    <Button
                                        href="https://educationadvisor.org/how-it-works/"
                                        variant='text'
                                        color='secondary'
                                        size='small'
                                    >
                                        How Is This Rating Calculated?
                                    </Button>
                                </Tooltip>
                            </Paper>
                        </Grid>
                        <Grid item md={12} lg={8}>

                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                <Grid item xs={6}>
                                    <Tooltip enterTouchDelay={0} title='The approximate monthly payment to pay off your schooling debt.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                                        <Typography component='div' fontWeight='bold' color='secondary'>
                                            Monthly Payment <InfoIcon sx={{fontSize: 'small'}} />
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'right'}}>
                                    <Typography color='primary' component='div' fontWeight='bold'>
                                    {calculatorComparison.suggestion.approximate_monthly_payments?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                <Grid item xs={6}>
                                    <Tooltip enterTouchDelay={0} title='The total number of years you will be paying off loans. If you have multiple loans some of those loans may be paid off before the end of this time frame.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                                        <Typography component='div' fontWeight='bold' color='secondary'>
                                            Payoff Years <InfoIcon sx={{fontSize: 'small'}} />
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'right'}}>
                                    <Typography color='primary' component='div' fontWeight='bold'>
                                    {calculatorComparison.suggestion.total_payoff_years} Years
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                <Grid item xs={6}>
                                    <Tooltip enterTouchDelay={0} title='The total amount you will pay off including interest.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                                        <Typography component='div' fontWeight='bold' color='secondary'>
                                            Total Payment <InfoIcon sx={{fontSize: 'small'}} />
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'right'}}>
                                    <Typography color='primary' component='div' fontWeight='bold'>
                                    ${calculatorComparison.suggestion.total_payment?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                <Grid item xs={6}>
                                    <Tooltip enterTouchDelay={0} title='The total amount of interest you will be paying off.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                                        <Typography component='div' fontWeight='bold' color='secondary'>
                                            Interest <InfoIcon sx={{fontSize: 'small'}} />
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'right'}}>
                                    <Typography color='primary' component='div' fontWeight='bold'>
                                    ${calculatorComparison.suggestion.total_interest?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                <Grid item xs={6}>
                                    <Tooltip enterTouchDelay={0} title='The percentage of your paycheck going towards paying off your student loans. This assumes a bi-weekly paycheck before taxes are taken out.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                                        <Typography component='div' fontWeight='bold' color='secondary'>
                                            % of Paycheck <InfoIcon sx={{fontSize: 'small'}} />
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'right'}}>
                                    <Typography color='primary' component='div' fontWeight='bold'>
                                    {calculatorComparison.suggestion.approximate_percentage_of_paycheck}
                                    </Typography>
                                </Grid>
                            </Grid>


                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} lg={6}>
                    <Typography component='div' variant='h6' fontWeight='bold' color='primary' sx={{textAlign:'center'}}>
                        {comparison.current} (Current)
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    <Grid container alignItems="center" justifyContent="center" spacing={2}>
                        <Grid item md={12} lg={4} sx={{textAlign:'center'}}>
                            <Paper elevation={0} sx={{backgroundColor:'#f7f7f7', p: 2, border: '1px solid #e2e2e2'}}>
                                <Typography component='div' fontWeight='bold' color='secondary'>
                                    YOUR INVESTMENT RATING
                                </Typography>
                                <Typography color='primary' variant='h1' component='div'  sx={{ mt: 2, mb: 2 }} fontSize='120px'>
                                    {calculatorComparison.current.rating}
                                </Typography>
                                <Tooltip enterTouchDelay={0} title='This rating is calculated based on the average amount of money you are going to make in your industry versus the loan amount to payoff. Click for more information on how our calculations work.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                                    <Button
                                        href="https://educationadvisor.org/how-it-works/"
                                        variant='text'
                                        color='secondary'
                                        size='small'
                                    >
                                        How Is This Rating Calculated?
                                    </Button>
                                </Tooltip>
                            </Paper>
                        </Grid>
                        <Grid item md={12} lg={8}>

                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                <Grid item xs={6}>
                                    <Tooltip enterTouchDelay={0} title='The approximate monthly payment to pay off your schooling debt.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                                        <Typography component='div' fontWeight='bold' color='secondary'>
                                            Monthly Payment <InfoIcon sx={{fontSize: 'small'}} />
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'right'}}>
                                    <Typography color='primary' component='div' fontWeight='bold'>
                                    {calculatorComparison.current.approximate_monthly_payments?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                <Grid item xs={6}>
                                    <Tooltip enterTouchDelay={0} title='The total number of years you will be paying off loans. If you have multiple loans some of those loans may be paid off before the end of this time frame.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                                        <Typography component='div' fontWeight='bold' color='secondary'>
                                            Payoff Years <InfoIcon sx={{fontSize: 'small'}} />
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'right'}}>
                                    <Typography color='primary' component='div' fontWeight='bold'>
                                    {calculatorComparison.current.total_payoff_years} Years
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                <Grid item xs={6}>
                                    <Tooltip enterTouchDelay={0} title='The total amount you will pay off including interest.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                                        <Typography component='div' fontWeight='bold' color='secondary'>
                                            Total Payment <InfoIcon sx={{fontSize: 'small'}} />
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'right'}}>
                                    <Typography color='primary' component='div' fontWeight='bold'>
                                    ${calculatorComparison.current.total_payment?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                <Grid item xs={6}>
                                    <Tooltip enterTouchDelay={0} title='The total amount of interest you will be paying off.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                                        <Typography component='div' fontWeight='bold' color='secondary'>
                                            Interest <InfoIcon sx={{fontSize: 'small'}} />
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'right'}}>
                                    <Typography color='primary' component='div' fontWeight='bold'>
                                    ${calculatorComparison.current.total_interest?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                <Grid item xs={6}>
                                    <Tooltip enterTouchDelay={0} title='The percentage of your paycheck going towards paying off your student loans. This assumes a bi-weekly paycheck before taxes are taken out.' placement='bottom' arrow PopperProps={{style:{zIndex:9999}}}>
                                        <Typography component='div' fontWeight='bold' color='secondary'>
                                            % of Paycheck <InfoIcon sx={{fontSize: 'small'}} />
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'right'}}>
                                    <Typography color='primary' component='div' fontWeight='bold'>
                                    {calculatorComparison.current.approximate_percentage_of_paycheck}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Box sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 355,
            }}>
                {type === 'occupation' ? (
                    <ResponsiveContainer width='100%' height='100%'>
                        <LineChart
                            data={chartData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis dataKey='year' />
                            <YAxis domain={[0,Math.round(loanAmount/10000)*10000]} />
                            <Legend />
                            <Line type='monotone' dataKey='la' name='Loan Principal Amount' stroke='#fa7918' label={<CustomizedLabel />} />
                            <Line type='monotone' dataKey='pi' name='Current Projected Income' stroke={currentColor} label={<CustomizedLabel />} />
                            <Line type='monotone' dataKey='spi' name='Suggested Projected Income' stroke={suggestedColor} label={<CustomizedLabel />} />
                        </LineChart>
                    </ResponsiveContainer>
                ) : (
                    <ResponsiveContainer width='100%' height='100%'>
                        <LineChart
                            data={chartData}
                                margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis dataKey='year' />
                            <YAxis domain={[0,Math.round(loanAmount/10000)*10000]} />
                            <Legend />
                            <Line type='monotone' dataKey='la' name='Current Loan Principal Amount' stroke={currentColor} label={<CustomizedLabel />} />
                            <Line type='monotone' dataKey='sla' name='Suggested Loan Principal Amount' stroke={suggestedColor} label={<CustomizedLabel />} />
                            <Line type='monotone' dataKey='pi' name='Projected Income' stroke='#fa7918' label={<CustomizedLabel />} />
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </Box>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
        </>
            
    );
}